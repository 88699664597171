import { RowProps, SpaceProps } from 'antd';
import type { ThemeConfig } from 'antd/es/config-provider/context';
import type { CSSProperties } from 'react';

export const themeColors = {
    primary: '#00803C',
    secondary: '#D09B2C',
    success: '',
    warning: '#FAAD14',
    error: '#FF4D4F',
    info: '',
    netral: '#78909C',
};

export const darkThemeColors = {
    primary: '#001213',
};

export const iconActionTableStyle: CSSProperties = {
    color: themeColors.primary,
    fontSize: '14px',
};

export const sidebarThemeConfig: ThemeConfig = {
    components: {
        Menu: {
            colorItemText: '#ffffff',
            colorItemTextHover: '#ffffff',
            colorItemTextSelected: '#ffffff',
            colorItemBgHover: '#D09B2C',
            colorItemBgSelected: '#D09B2C',
            fontSize: 12,
            colorSubItemBg: '#007235',
            colorBgContainer: '#00803C',
        },
    },
};

export const calendarThemeConfig: ThemeConfig = {
    token: {
        colorTextDisabled: '#cccccc',
    },
    components: {
        Badge: {
            fontSize: 10,
        },
        Calendar: {
            fontSize: 12,
        },
        Popover: {
            fontSize: 10,
        },
    },
};

export const defaultGutter: RowProps['gutter'] = [16, 16];

export const defaultSizeSpace: SpaceProps['size'] = 'middle';

export const globalThemeConfig: ThemeConfig = {
    token: {
        colorPrimary: themeColors.primary,
        colorTextDisabled: '#000000b3',
    },
    components: {
        Table: {
            controlItemBgActive: '#FFF',
            controlItemBgActiveHover: '#FFF',
        },
        Button: {
            colorPrimary: themeColors.primary,
            colorPrimaryBg: themeColors.primary,
        },
    },
};
