/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { InertiaApp } from '@inertiajs/inertia-react';
import React from 'react';
import 'react-quill/dist/quill.snow.css';
import { AppProvider } from './Contexts/App';
import { globalThemeConfig } from './Utils/theme.ts';
import { CookiesProvider } from 'react-cookie';
import { QueryClient, QueryClientProvider } from 'react-query';

//Ant Design
import 'antd/dist/reset.css';
import { ConfigProvider, Empty, Row } from 'antd';

// Create a client
const queryClient = new QueryClient();

const App = () => {
    // TEMP disabled
    // OneSignal.init({
    //     appId: process.env.ONE_SIGNAL_APP_ID,
    //     allowLocalhostAsSecureOrigin: true,
    // }).then(() => {
    //     OneSignal.isPushNotificationsEnabled((isEnabled) => {
    //         if (!isEnabled) {
    //             OneSignal.showSlidedownPrompt({
    //                 force: true,
    //                 forceSlidedownOverNative: true,
    //             });
    //         }
    //     });
    // });

    return (
        <>
            <CookiesProvider>
                <QueryClientProvider client={queryClient}>
                    <ConfigProvider
                        theme={globalThemeConfig}
                        renderEmpty={() => (
                            <Row justify="center" align="middle">
                                <Empty description="Tidak ada data" />
                            </Row>
                        )}
                    >
                        <AppProvider>
                            <InertiaApp
                                initialPage={JSON.parse(app.dataset.page)}
                                resolveComponent={(pageString) =>
                                    import(`./Pages/${pageString}`).then(
                                        (module) => module.default,
                                    )
                                }
                            />
                        </AppProvider>
                    </ConfigProvider>
                </QueryClientProvider>
            </CookiesProvider>
        </>
    );
};

export default App;
